@font-face {
  font-family: "proxima_nova_rgbold";
  src: url("../src/assets/fonts/proximanova-bold-webfont.woff2") format("woff2"),
    url("../src/assets/fonts/proximanova-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "proxima_novalight";
  src: url("../src/assets/fonts/proximanova-light-webfont.woff2")
      format("woff2"),
    url("../src/assets/fonts/proximanova-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "proxima_novaregular";
  src: url("../src/assets/fonts/proximanova-reg-webfont.woff2") format("woff2"),
    url("../src/assets/fonts/proximanova-reg-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "proxima_novasemibold";
  src: url("../src/assets/fonts/proximanova-sbold-webfont.woff2")
      format("woff2"),
    url("../src/assets/fonts/proximanova-sbold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
body {
  font-family: "proxima_novaregular";
}
.ant-table-placeholder {
  .ant-empty-normal {
    margin: 0;
  }
}
.ant-form-item {
  margin: 0;

  label {
    color: #b0b0b0;
  }
}
